import styled from 'styled-components';
import { breakpoint } from 'theme';
import { DefaultType } from 'types/default';

import Icon from 'components/atoms/icon';
import Typography from 'components/atoms/typography';

type ContactWrapperProps = {
  hasDataContact?: boolean;
};
export const StyledWrapper = styled.div<DefaultType>`
  background-color: ${({ theme, bgColor }) => bgColor || theme.colors.grayscaleGray0};
  padding: ${({ theme, mobilePadding }) => mobilePadding || `${theme.gap.mdl} 16px`};
  ${breakpoint.md} {
    padding: ${({ theme, padding }) => padding || `${theme.gap.md} 0`};
  }
`;

export const StyledContent = styled.div<{ maxWidth?: string }>`
  margin: 0 auto;
  max-width: ${({ theme, maxWidth }) => maxWidth || theme.wrapper};
`;

export const StyledContactList = styled.div`
  margin-top: ${({ theme }) => theme.gap.smd};
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.gap.xs};
  ${breakpoint.md} {
    margin-top: ${({ theme }) => theme.gap.md};
    gap: ${({ theme }) => theme.gap.sm};
  }
`;

export const StyledContact = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.gap.sm};

  ${breakpoint.md} {
    padding: ${({ theme }) => theme.gap.smd};
    width: calc(100% / 3 - 11px);
  }
`;

export const StyledLinkButton = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.gap.xs};
`;

export const StyledTitleBox = styled.div`
  margin-bottom: ${({ theme }) => theme.gap.xs};
  ${breakpoint.md} {
    margin-bottom: ${({ theme }) => theme.gap.sm};
  }
`;

export const StyledPlace = styled(Typography)`
  margin-top: ${({ theme }) => theme.gap.xxs};

  &[data-variant='body1'] {
    font-weight: 700;
  }
`;
export const WrapperContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.gap.xxs};

  ${breakpoint.md} {
    gap: ${({ theme }) => theme.gap.xs};
  }
  [data-variant='primary'] {
    width: 100%;
  }
`;
export const StyledIcon = styled(Icon)`
  &.phone {
    color: ${({ theme }) => theme.colors.gray80};
  }
`;

export const StyledData = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.gap.xs};
  ${breakpoint.md} {
    margin-bottom: ${({ theme }) => theme.gap.smd};
  }
`;
export const StyledDataContact = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.gap.xs};
`;
export const ContactWrapper = styled.div<ContactWrapperProps>`
  margin-bottom: ${(props) => (props.hasDataContact ? '16px' : '0')};
`;
