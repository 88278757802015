import React from 'react';
import { DefaultType } from 'types/default';

import Button, { ButtonType } from 'components/atoms/button';
import Typography from 'components/atoms/typography';

import { StyledContent, StyledWrapper } from './sended-info.styles';

export type SendedInfoType = {
  text: string;
  heading: string;
  button: ButtonType;
  newRequest: () => void;
} & DefaultType;

const SendedInfo = ({ text, heading, button, newRequest, mobilePadding }: SendedInfoType) => (
  <StyledWrapper mobilePadding={mobilePadding}>
    <StyledContent>
      <Typography variant="title2" component="h2" html={heading} />
      <Typography variant="title3" color="gray60" component="h3" html={text} />
      <Button {...button} onClick={newRequest} />
    </StyledContent>
  </StyledWrapper>
);

export default SendedInfo;
