import styled from 'styled-components';
import { breakpoint } from 'theme';
import { DefaultType } from 'types/default';

import Button from 'components/atoms/button';
import Typography from 'components/atoms/typography';

export const StyledWrapper = styled.div<DefaultType>`
  padding: ${({ theme, mobilePadding }) => mobilePadding || theme.gap.sm};

  ${breakpoint.md} {
    padding: ${({ theme, padding }) => padding || `${theme.gap.xl} 0`};
  }
`;

export const StyledContent = styled.div<{ maxWidth?: string }>`
  margin: 0 auto;
  max-width: ${({ maxWidth }) => maxWidth || '1110px'};
`;

export const StyledForm = styled.form`
  ${breakpoint.md} {
    width: 635px;
  }
`;
export const StyledFormBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.gap.sm};
`;

export const StyledDescription = styled(Typography)`
  margin: ${({ theme }) => `${theme.gap.xs} 0 ${theme.gap.smd}`};
  ${breakpoint.md} {
    margin: ${({ theme }) => `${theme.gap.sm} 0 ${theme.gap.md}`};
    max-width: 635px;
  }
`;

export const StyledFormItem = styled.div<{ message?: boolean }>`
  margin-top: ${({ theme, message }) => (message ? theme.gap.sm : 0)};
  position: relative;
  width: 100%;
  label {
    display: block;
  }

  textarea,
  input {
    width: 100%;
    margin-top: ${({ theme }) => theme.gap.xxs};
    padding: 12px 16px;
    border: 1px solid #cdd1dc;
  }

  textarea {
    min-height: 198px;
  }

  ${breakpoint.md} {
    width: ${({ message }) => (message ? '97%' : '47%')};

    textarea,
    input {
      margin-top: ${({ theme }) => theme.gap.xs};
    }

    textarea {
      min-height: 111px;
    }
  }
`;

export const StyledLabel = styled.label``;

export const StyledCollapse = styled.div<{ isOpen?: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const StyledCollapseItem = styled.div`
  padding: ${({ theme }) => theme.gap.sm};
  border: 1px solid #cdd1dc;
  z-index: 10;
  cursor: pointer;
`;

export const StyledConsent = styled.div`
  margin: ${({ theme }) => `${theme.gap.smd} 0 ${theme.gap.smd}`};
  display: flex;

  ${breakpoint.md} {
    margin: ${({ theme }) => `${theme.gap.md} 0`};
  }
`;

export const StyledButton = styled(Button)`
  margin-bottom: ${({ theme }) => theme.gap.sm};
`;
