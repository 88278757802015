import styled from 'styled-components';
import { breakpoint } from 'theme';
import { DefaultType } from 'types/default';

export const StyledWrapper = styled.div<DefaultType>`
  padding: ${({ theme, mobilePadding }) => mobilePadding || theme.gap.sm};
`;

export const StyledContent = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray10};
  padding: ${({ theme }) => `${theme.gap.md} ${theme.gap.sm}`};
  max-width: ${({ theme }) => theme.wrapper};
  gap: ${({ theme }) => theme.gap.sm};
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  ${breakpoint.md} {
    padding: ${({ theme }) => theme.gap.xl};
  }
`;
