import React from 'react';
import { PageContextType } from 'types';

import Breadcrumb from 'components/atoms/breadcrumb';
import { IconType } from 'components/atoms/icon';
import { WpImageType } from 'components/atoms/wp-image';

import { useScreenService } from 'hooks/use-screen-service';

import {
  StyledBreadcrumbWrapper,
  StyledContainer,
  StyledContent,
  StyledDescription,
  StyledDotsGridOne,
  StyledDotsGridTwo,
  StyledIcon,
  StyledImageContainer,
  StyledSection,
  StyledTitle,
  StyledWpImage,
  StyledWrapper,
} from './industries-banner.styles';

export type IndustriesBannerType = {
  heading: string;
  subHeading: string;
  text: string;
  icon?: IconType;
  image: WpImageType;
  isWhite?: boolean;
  isWhiteBreadcrumb?: boolean;
  isPaddingText?: boolean;
};

export type IndustriesBannerPropsType = {
  pageContext: PageContextType;
} & IndustriesBannerType;

const IndustriesBanner = ({
  heading,
  text,
  icon,
  image,
  pageContext,
  isWhite,
  isWhiteBreadcrumb,
  isPaddingText,
}: IndustriesBannerPropsType) => {
  const { isLgUp } = useScreenService();

  return (
    <StyledWrapper isWhite={isWhite}>
      <StyledContainer>
        <StyledContent isPaddingText={isPaddingText}>
          <StyledBreadcrumbWrapper isWhiteBreadcrumb={isWhiteBreadcrumb}>
            <Breadcrumb {...{ pageContext }} />
          </StyledBreadcrumbWrapper>
          <StyledSection>
            {icon && <StyledIcon name={icon.name} />}
            <StyledTitle variant="title1" color={isWhite ? 'gray100' : 'white'} html={heading} />
            <StyledDescription
              color={isWhite ? 'gray60' : 'whiteAlpha72'}
              variant="title2"
              component="h2"
              html={text}
            />
          </StyledSection>
        </StyledContent>
        <StyledImageContainer>
          <StyledWpImage {...image} />
          <StyledDotsGridOne
            width={7}
            height={7}
            color={isLgUp ? 'complementary2' : 'complementary3'}
          />
        </StyledImageContainer>
        <StyledDotsGridTwo
          width={2}
          height={7}
          color={isLgUp ? 'complementary3' : 'complementary2'}
        />
      </StyledContainer>
    </StyledWrapper>
  );
};

export default IndustriesBanner;
