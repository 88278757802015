import React from 'react';
import { DefaultType } from 'types/default';

import { IconNameType } from 'components/atoms/icon';
import Typography from 'components/atoms/typography';

import Button from '../../atoms/button';
import { LinkUrlType } from '../../atoms/link';

import {
  ContactWrapper,
  StyledContact,
  StyledContactList,
  StyledContent,
  StyledData,
  StyledDataContact,
  StyledIcon,
  StyledLinkButton,
  StyledPlace,
  StyledTitleBox,
  StyledWrapper,
  WrapperContactInfo,
} from './contact-us-directly-section.styles';

export type ContactUsDirectlySectionType = {
  heading: string;
  contactCards: {
    title: string;
    place: string;
    data: string;
    contactList: {
      dataContact?: string;
      buttonLink?: boolean;
      linkButton: {
        icon: {
          name: IconNameType;
        };
        label: string;
        link?: LinkUrlType;
      };
    }[];
  }[];
} & DefaultType;

const ContactUsDirectlySection = ({
  heading,
  contactCards,
  margin,
  padding,
  bgColor,
  mobilePadding,
}: ContactUsDirectlySectionType) => (
  <StyledWrapper margin={margin} padding={padding} mobilePadding={mobilePadding} bgColor={bgColor}>
    <StyledContent>
      <Typography variant="title2" html={heading} />
      <StyledContactList>
        {contactCards.map((contactCard) => (
          <StyledContact key={contactCard.title}>
            <StyledTitleBox>
              <Typography
                variant="title3"
                component="h3"
                color="primary50"
                html={contactCard.title}
              />
              <StyledPlace variant="body1" color="gray60" html={contactCard.place} />
            </StyledTitleBox>
            <StyledData variant="title4" color="gray80" html={contactCard.data} />
            <WrapperContactInfo>
              {contactCard.contactList?.map((contact) => (
                <ContactWrapper hasDataContact={!!contact.dataContact}>
                  {contact.dataContact ? (
                    <StyledDataContact variant="title4" color="gray80" html={contact.dataContact} />
                  ) : null}
                  <StyledLinkButton>
                    {contact.linkButton?.icon && (
                      <StyledIcon
                        name={contact.linkButton.icon.name}
                        className={contact.linkButton.icon.name}
                      />
                    )}
                    {(contact.buttonLink && contact.linkButton?.link?.external?.url) ||
                    contact.linkButton?.link?.internal ? (
                      <Button
                        width="max-content"
                        link={contact.linkButton.link}
                        label={contact.linkButton.label}
                        variant="primary"
                      />
                    ) : (
                      <Typography variant="title4" color="gray80" html={contact.linkButton.label} />
                    )}
                  </StyledLinkButton>
                </ContactWrapper>
              ))}
            </WrapperContactInfo>
          </StyledContact>
        ))}
      </StyledContactList>
    </StyledContent>
  </StyledWrapper>
);

export default ContactUsDirectlySection;
